import React from 'react';
import '../scss/Links.scss';
import poofImage from '../images/poof.png';
import soundcloudImage from '../images/soundcloud.png';
import instagramImage from '../images/instagram.png';
import spotifyImage from '../images/spotify.png';
import secretwordImage from '../images/secret-word.png';
import appleMusicImage from '../images/apple-music.png';
import youtubeMusicImage from '../images/youtube-music.png';
import tiktokImage from '../images/tiktok.png';
import youtubeImage from '../images/youtube.png';
import discordImage from '../images/discord.png';
import newgroundsImage from '../images/newgrounds.png';

function Links() {
  return (
    <div className="links-container">
      <div className="profile">
        <img src={poofImage} className="profile-image" alt="madpoof" />
        <h1>MADPOOF</h1>
        <p>The Cyberproducer 💻</p>
      </div>
      <div className="links">
        <p>Join the CyberArmy on Discord, right below</p>
        <a href="https://discord.gg/8Ds6NWbRFQ" className="link">
          <img src={discordImage} alt="Spotify" className="icon" />Join Discord Server
        </a>
        <a href="https://open.spotify.com/artist/5MMWP4xWHgpaLUJtgBTXgg?si=ca1FBboBTUixtcHmbmHcNA" className="link">
          <img src={spotifyImage} alt="Spotify" className="icon" />Spotify
        </a>
        <a href="https://www.youtube.com/channel/UC6Q2kj0gt-gS5DuRTVbob2g" className="link">
          <img src={youtubeImage} alt="YouTube" className="icon" />YouTube
        </a>
        <a href="https://instagram.com/mad.poof" className="link">
          <img src={instagramImage} alt="Instagram" className="icon" />Instagram
        </a>
        <a href="https://music.apple.com/us/artist/madpoof/1751584955" className="link">
          <img src={appleMusicImage} alt="Apple Music" className="icon" />Apple Music
        </a>
        <a href="https://music.youtube.com/channel/UCT6umebaOQloPb64z9gh-Tw" className="link">
          <img src={youtubeMusicImage} alt="YouTube Music" className="icon" />YouTube Music
        </a>
        <a href=" https://poof508.newgrounds.com/" className="link">
          <img src={newgroundsImage} alt="NewGrounds" className="icon" />NewGrounds
        </a>
        <a href="https://soundcloud.com/madpoof" className="link">
          <img src={soundcloudImage} alt="Soundcloud" className="icon" />Soundcloud
        </a>
        <a href="https://www.tiktok.com/@mad.poof?_t=8nA8MBfKDBp&_r=1" className="link">
          <img src={tiktokImage} alt="TikTok" className="icon" />TikTok
        </a>
        <a href="https://madpoof.com" className="link">
          <img src={secretwordImage} alt="madpoof" className="icon" />Guess the secret word
        </a>
      </div>
    </div>
  );
}

export default Links;
